<template>
    <div class="about">
        <div class="site-content" >
            <div class="block">
                <span v-for="(row, index) in photos" :key="index" >
                    <el-card shadow="never" style="margin-bottom: 10px">
                        <el-descriptions class="margin-top" :column="1" border style="margin: 20px" labelClassName="labelClassName">
                                <el-descriptions-item>
                                    <template slot="label">
                                        日期
                                    </template>
                                    {{row.date}}
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        地点
                                    </template>
                                    {{row.addr}}
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        相册描述
                                    </template>
                                    {{row.desc}}
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        相册
                                    </template>
                                    <el-card>
                                        <div class="demo-image__lazy">
                                            <div style="display: inline-block; margin:10px" v-for="(urls, key) in row.list" :key="key" type="contain" class="row-bg" justify="space-around">
                                                <el-image style="width: 100px; height: 100px" :src="urls.url" :preview-src-list="[urls.pre_url]" lazy></el-image>
                                            </div>
                                        </div>
                                    </el-card>
                                </el-descriptions-item>
                            </el-descriptions>
                    </el-card>
                </span>
            </div>
        </div>
    </div>
</template>
<script>
    import {fetchPhotos} from "../api";
    export default {
        name: "PhotosPage",
        data() {
            return {
                photos: []
            }
        },
        methods: {
            getData() {
                fetchPhotos().then(res => {
                    if (res.code === 200) {
                        let result = this.$secret.changeJson(res.data)
                        this.photos = result.list
                    }
                })
            },

        },
        mounted() {
            this.getData()
        }
    }
</script>
<style lang="less">
    .about {
        padding-top: 80px;
    }
    .site-content {
        max-width: 1200px;
        margin: 0 auto;
        background-color: rgba(255, 255, 255, 0.68);
    }
    .demo-image__lazy {
        height: 200px;
        overflow-y: auto;
    }
    .el-image-viewer__close {
        background: none;
    }
    .labelClassName {
        width: 100px;
    }
</style>
